.editor-control-panel-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  z-index: 20;
  height: 36px;
  margin-bottom: 20px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card {
  height: 34px;
  border-bottom: 1px solid;
  border-radius: 0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 100%;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card.parent-control-panel {
  will-change: opacity, transform;
  transition: all 0.3s ease-in-out;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card.parent-control-panel .editor-control-panel {
  padding-left: 1rem !important;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card.parent-control-panel .editor-control-panel-container {
  display: flex;
  align-items: center;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card.parent-control-panel.hide {
  opacity: 0;
  -webkit-transform: translate(-50%, -20%) scale(0.97);
          transform: translate(-50%, -20%) scale(0.97);
  pointer-events: none;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card.parent-control-panel .editor-control-font-color-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body {
  padding: 0px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-skeleton.ant-skeleton {
  height: 32px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-skeleton.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel {
  height: 32px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 8px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .merge-tags-dropdown {
  position: absolute;
  top: 40px;
  right: 0px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .control-bar-span {
  font-size: 14px;
  color: #5670a1;
  padding: 0px 6px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-section-divider.ant-divider.ant-divider-vertical {
  top: 0px;
  height: 18px;
  margin: 0px 2px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-right: 8px;
  width: 384px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .ant-select {
  margin-right: 8px;
  min-width: 96px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container {
  border-radius: 0.5rem;
  width: 40px;
  height: 26px;
  display: flex;
  align-items: center;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .editor-control-theme {
  margin: auto;
  width: 34px;
  height: 20px;
  border-radius: 4px;
  background-color: black;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .editor-control-theme:hover {
  cursor: pointer;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .dark-blue {
  background-color: #0c1d33;
  color: white;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .mid-blue {
  background-color: #5670a1;
  color: white;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .bright-blue {
  background-color: #2f70ff;
  color: white;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .editor-control-panel-item-container .grey {
  background-color: #d8d8d8;
  color: black;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control-panel-theme-container .selected-theme-container {
  border-radius: 6px;
  border: 1px solid #2f70ff;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control {
  margin: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control:hover {
  cursor: pointer;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.disabled-icon {
  cursor: not-allowed;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-icon {
  height: 32px;
  line-height: 32px;
  width: 26px;
  padding: 0px 6px;
  transition: all 0.25s ease;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-toggle {
  height: 32px;
  line-height: 32px;
  width: 24px;
  padding: 4px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-bold.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-bold:hover svg path {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-italic.active svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-underline.active svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-italic:hover svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-underline:hover svg path:nth-child(2) {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color .editor-control-font-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color .editor-control-font-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list .editor-control-font-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list .editor-control-font-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color .editor-control-background-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color .editor-control-background-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list .editor-control-background-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list .editor-control-background-color-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color .editor-control-ordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color .editor-control-ordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list .editor-control-ordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list .editor-control-ordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color .editor-control-unordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color .editor-control-unordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list .editor-control-unordered-list-menu-container,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list .editor-control-unordered-list-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color.active .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color.active .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list.active .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list.active .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color:hover .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color:hover .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list:hover .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list:hover .editor-control-font-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color.active .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color.active .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list.active .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list.active .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-font-color:hover .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color:hover .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list:hover .editor-control-background-color-icon svg path:nth-child(2),
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list:hover .editor-control-background-color-icon svg path:nth-child(2) {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color svg {
  stroke-width: 0;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-background-color svg path:nth-child(1) {
  fill: #0c1d33 !important;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu-label {
  height: 32px;
  line-height: 32px;
  width: auto;
  padding: 0px 4px;
  color: #5670a1;
  cursor: auto;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input.ant-input-group {
  padding: 0px 6px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input.ant-input-group.number-with-suffix-input {
  width: 100px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input.ant-input-group.number-with-suffix-input .has-suffix {
  width: calc(100% - 32px);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input.ant-input-group.number-with-suffix-input .input-suffix {
  width: 32px;
  border-left: 0px;
  float: right;
  pointer-events: none;
  background-color: #fafafa;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .ant-select-selector {
  padding: 0px 4px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .ant-select-selector .ant-select-selection-item {
  padding-right: 22px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size {
  width: 110px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection {
  width: 110px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family {
  width: 120px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection {
  width: 120px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format {
  width: 90px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection {
  width: 90px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-ordered-list-menu-dropdown-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-unordered-list-menu-dropdown-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  background-position: 95% 50%;
  justify-content: left;
  background-repeat: no-repeat;
  background-size: 6px;
  border: none;
  border-radius: 0px;
  padding: 4px 0px;
  position: relative;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-ordered-list-menu-dropdown-icon .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-unordered-list-menu-dropdown-icon .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection__rendered {
  padding-right: 15px;
  margin: 0 11px;
  min-width: 3rem;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-ordered-list-menu-dropdown-icon .custom-suffix-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu .editor-control-unordered-list-menu-dropdown-icon .custom-suffix-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .custom-suffix-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .custom-suffix-icon,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .custom-suffix-icon {
  position: absolute;
  top: 45%;
  right: -11px;
  height: 20px;
  width: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2221%22 height%3D%2221%22 viewBox%3D%220 0 21 21%22%3E    %3Cpath fill%3D%22%230C1D33%22 fill-rule%3D%22nonzero%22 d%3D%22M7.667 7.917a.833.833 0 0 1 .591.241L11 10.917l2.75-2.65a.833.833 0 1 1 1.175 1.183l-3.333 3.217a.833.833 0 0 1-1.167 0L7.092 9.333a.833.833 0 0 1 0-1.183.833.833 0 0 1 .575-.233z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-display,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-align,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-video-align,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-signature-align {
  height: 32px;
  line-height: 16px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-display .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-align .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-video-align .ant-select-selection,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-signature-align .ant-select-selection {
  border: none;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-display .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-align .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-video-align .ant-select-selection .ant-select-selection__rendered,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-signature-align .ant-select-selection .ant-select-selection__rendered {
  height: 32px;
  line-height: 32px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-display .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-align .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-video-align .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-signature-align .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-right: 4px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-family .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-size .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-font-format .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-display .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-background-image-align .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-video-align .ant-select-selection .ant-select-arrow .anticon svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-menu.editor-control-signature-align .ant-select-selection .ant-select-arrow .anticon svg {
  fill: #0c1d33;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  vertical-align: middle;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-align img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-center-align img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-align img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-justify-align img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-align svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-center-align svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-align svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-justify-align svg {
  height: 18px;
  width: 18px;
  object-fit: contain;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-align.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-center-align.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-align.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-justify-align.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-align:hover svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-center-align:hover svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-align:hover svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-justify-align:hover svg path {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-indent img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-indent img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-indent svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-indent svg {
  height: 12px;
  width: 12px;
  object-fit: contain;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-indent.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-indent.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-left-indent:hover svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-right-indent:hover svg path:first-child {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-clear-format img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-clear-format svg {
  height: 18px;
  width: 18px;
  object-fit: contain;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-clear-format.active svg path:last-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-clear-format:hover svg path:last-child {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-insert-link img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-insert-link svg {
  height: 18px;
  width: 18px;
  object-fit: contain;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-insert-link.active svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-insert-link:hover svg path {
  fill: #2f70ff;
  stroke: #2f70ff;
  will-change: fill, stroke;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list svg {
  height: 18px;
  width: 18px;
  object-fit: contain;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-unordered-list:hover svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-ordered-list:hover svg path:first-child {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-merge-tags.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-merge-tags:hover svg path:first-child {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input-fields.active svg path:first-child,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-input-fields:hover svg path:first-child {
  fill: #2f70ff;
  will-change: fill;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-image-width,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-image-height {
  width: 80px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-image-width {
  margin-right: 8px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block {
  justify-content: left;
  padding-left: 0px;
  padding-right: 0px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block img,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block svg,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block svg {
  height: 18px;
  width: 18px;
  margin-right: 8px;
  object-fit: contain;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block img path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block img path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block img path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block svg path {
  fill: #0c1d33;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block:hover,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block:hover,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block:hover {
  color: #2f70ff;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block:hover svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block:hover svg path,
.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block:hover svg path {
  fill: #2f70ff;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-replace-image-block {
  width: 135px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-delete-block {
  width: 150px;
  padding: 0px 6px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control.editor-control-add-block {
  width: 105px;
  padding: 0px 0px 0px 6px;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .editor-control .dropdown-menu-icon {
  right: 3px;
  top: 47.5%;
  position: absolute;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .delete-icon {
  display: inline;
  color: #e2586c;
  cursor: pointer;
}

.editor-control-panel-wrapper .editor-control-panel-card.ant-card .ant-card-body .editor-control-panel-container .editor-control-panel .delete-icon svg path {
  fill: #e2586c;
}

.editor-control-panel-wrapper .child-control-panel {
  transition: all 0.3s ease-in-out;
  will-change: opacity, transform;
}

.editor-control-panel-wrapper .child-control-panel.child-control-panel-enter {
  opacity: 0;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.editor-control-panel-wrapper .child-control-panel.child-control-panel-enter-active {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.editor-control-panel-wrapper .child-control-panel.child-control-panel-exit {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 1;
}

.editor-control-panel-wrapper .child-control-panel.child-control-panel-exit-active {
  opacity: 0;
  -webkit-transform: translate(-50%, calc(-50% + 0.7rem));
          transform: translate(-50%, calc(-50% + 0.7rem));
}

.editor-control-panel-wrapper .tox-editor-container .tox-editor-header {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.editor-control-unordered-list-menu.ant-dropdown-menu, .editor-control-ordered-list-menu.ant-dropdown-menu {
  display: grid;
  list-style-type: none;
  min-width: 0px;
  padding: 8px;
  text-align: center;
  gap: 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.editor-control-unordered-list-menu.ant-dropdown-menu .ant-dropdown-menu-item, .editor-control-ordered-list-menu.ant-dropdown-menu .ant-dropdown-menu-item {
  line-height: 1;
  padding: 0px;
}

.editor-control-unordered-list-menu.ant-dropdown-menu .ant-dropdown-menu-item svg, .editor-control-ordered-list-menu.ant-dropdown-menu .ant-dropdown-menu-item svg {
  height: 32px;
  width: 32px;
}

.ant-select-dropdown.editor-control-image-block-panel,
.ant-select-dropdown.editor-control-quote-block-panel,
.ant-select-dropdown.editor-control-text-block-panel,
.ant-select-dropdown.editor-control-video-block-panel,
.ant-select-dropdown.editor-control-parent-control-panel {
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-modal,
.ant-image-preview {
  pointer-events: none;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}

.ant-modal-mask-hidden,
.ant-image-preview-mask-hidden {
  display: none;
}

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-legacy-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}

.ant-legacy-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-legacy-form label {
  font-size: 14px;
}

.ant-legacy-form input[type='search'] {
  box-sizing: border-box;
}

.ant-legacy-form input[type='radio'],
.ant-legacy-form input[type='checkbox'] {
  line-height: normal;
}

.ant-legacy-form input[type='file'] {
  display: block;
}

.ant-legacy-form input[type='range'] {
  display: block;
  width: 100%;
}

.ant-legacy-form select[multiple],
.ant-legacy-form select[size] {
  height: auto;
}

.ant-legacy-form input[type='file']:focus,
.ant-legacy-form input[type='radio']:focus,
.ant-legacy-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ant-legacy-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-legacy-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-legacy-form-hide-required-mark .ant-legacy-form-item-required::before {
  display: none;
}

.ant-legacy-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}

.ant-legacy-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-legacy-form-item-label > label.ant-legacy-form-item-no-colon::after {
  content: ' ';
}

.ant-legacy-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-legacy-form-item label {
  position: relative;
}

.ant-legacy-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}

.ant-legacy-form-item-control {
  position: relative;
  line-height: 40px;
}

.ant-legacy-form-item-control::before {
  display: table;
  content: '';
}

.ant-legacy-form-item-control::after {
  display: table;
  clear: both;
  content: '';
}

.ant-legacy-form-item-children {
  position: relative;
}

.ant-legacy-form-item-with-help {
  margin-bottom: 4px;
}

.ant-legacy-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  flex-grow: 0;
}

.ant-legacy-form-item-label-left {
  text-align: left;
}

.ant-legacy-form-item-control-wrapper {
  flex: 1 1 0;
}

.ant-legacy-form-item .ant-switch {
  margin: 2px 0 4px;
}

.ant-legacy-form-explain,
.ant-legacy-form-extra {
  clear: both;
  min-height: 23px;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-legacy-form-explain {
  margin-bottom: -1px;
}

.ant-legacy-form-extra {
  padding-top: 4px;
}

.ant-legacy-form-text {
  display: inline-block;
  padding-right: 8px;
}

.ant-legacy-form-split {
  display: block;
  text-align: center;
}

form .has-feedback .ant-input {
  padding-right: 30px;
}

form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}

form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px;
}

form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px;
}

form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}

form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}

form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}

form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}

form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}

form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}

form .has-feedback .ant-picker .ant-picker-suffix {
  padding-right: 17px;
}

form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}

form .ant-upload {
  background: transparent;
}

form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}

form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}

form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}

form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}

form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}

form .ant-input-number + .ant-legacy-form-text {
  margin-left: 8px;
}

form .ant-input-number-handler-wrap {
  z-index: 2;
}

form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}

form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}

form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label,
.ant-col-24.ant-legacy-form-item-label,
.ant-col-xl-24.ant-legacy-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  flex-basis: 100%;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label label::after,
.ant-col-24.ant-legacy-form-item-label label::after,
.ant-col-xl-24.ant-legacy-form-item-label label::after {
  display: none;
}

.ant-legacy-form-vertical .ant-legacy-form-item {
  padding-bottom: 8px;
}

.ant-legacy-form-vertical .ant-legacy-form-item-control {
  line-height: 1.5715;
}

.ant-legacy-form-vertical .ant-legacy-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}

.ant-legacy-form-vertical .ant-legacy-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}

@media (max-width: 575px) {
  .ant-legacy-form-item-label,
  .ant-legacy-form-item-control-wrapper {
    display: block;
    width: 100%;
  }

  .ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-legacy-form-item-label label::after {
    display: none;
  }

  .ant-legacy-form-item-control-wrapper {
    flex-basis: 100%;
  }

  .ant-col-xs-24.ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-col-xs-24.ant-legacy-form-item-label label::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-col-sm-24.ant-legacy-form-item-label label::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-col-md-24.ant-legacy-form-item-label label::after {
    display: none;
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-col-lg-24.ant-legacy-form-item-label label::after {
    display: none;
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
  }

  .ant-col-xl-24.ant-legacy-form-item-label label::after {
    display: none;
  }
}

.ant-legacy-form-inline .ant-legacy-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}

.ant-legacy-form-inline .ant-legacy-form-item-with-help {
  margin-bottom: 24px;
}

.ant-legacy-form-inline .ant-legacy-form-item > .ant-legacy-form-item-control-wrapper,
.ant-legacy-form-inline .ant-legacy-form-item > .ant-legacy-form-item-label {
  display: inline-block;
  vertical-align: top;
}

.ant-legacy-form-inline .ant-legacy-form-text {
  display: inline-block;
}

.ant-legacy-form-inline .has-feedback {
  display: inline-block;
}

.has-success.has-feedback .ant-legacy-form-item-children-icon,
.has-warning.has-feedback .ant-legacy-form-item-children-icon,
.has-error.has-feedback .ant-legacy-form-item-children-icon,
.is-validating.has-feedback .ant-legacy-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.has-success.has-feedback .ant-legacy-form-item-children-icon svg,
.has-warning.has-feedback .ant-legacy-form-item-children-icon svg,
.has-error.has-feedback .ant-legacy-form-item-children-icon svg,
.is-validating.has-feedback .ant-legacy-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.has-success.has-feedback .ant-legacy-form-item-children-icon {
  color: #52c41a;
  animation-name: diffZoomIn1 !important;
}

.has-warning .ant-legacy-form-explain,
.has-warning .ant-legacy-form-split {
  color: #faad14;
}

.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}

.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}

.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}

.has-warning .ant-input-prefix {
  color: #faad14;
}

.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}

.has-warning .has-feedback {
  color: #faad14;
}

.has-warning.has-feedback .ant-legacy-form-item-children-icon {
  color: #faad14;
  animation-name: diffZoomIn3 !important;
}

.has-warning .ant-select-selection {
  border-color: #faad14;
}

.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}

.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}

.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}

.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}

.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #faad14;
}

.has-warning .ant-input-affix-wrapper {
  border-color: #faad14;
}

.has-warning .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.has-warning .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none;
}

.has-error .ant-legacy-form-explain,
.has-error .ant-legacy-form-split {
  color: #ff4d4f;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.has-error .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-input:not([disabled]):hover {
  border-color: #ff4d4f;
}

.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff4d4f;
}

.has-error .ant-input-prefix {
  color: #ff4d4f;
}

.has-error .ant-input-group-addon {
  color: #ff4d4f;
  background-color: #fff;
  border-color: #ff4d4f;
}

.has-error .has-feedback {
  color: #ff4d4f;
}

.has-error.has-feedback .ant-legacy-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
}

.has-error .ant-select-selection {
  border-color: #ff4d4f;
}

.has-error .ant-select-selection:hover {
  border-color: #ff4d4f;
}

.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f;
}

.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}

.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #ff4d4f;
}

.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #ff4d4f;
}

.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #ff4d4f;
}

.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #ff4d4f;
}

.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #ff4d4f;
}

.has-error .ant-transfer-list {
  border-color: #ff4d4f;
}

.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.has-error .ant-input-affix-wrapper {
  border-color: #ff4d4f;
}

.has-error .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.has-error .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none;
}

.is-validating.has-feedback .ant-legacy-form-item-children-icon {
  display: inline-block;
  color: #1890ff;
}

.ant-advanced-search-form .ant-legacy-form-item {
  margin-bottom: 24px;
}

.ant-advanced-search-form .ant-legacy-form-item-with-help {
  margin-bottom: 4px;
}

.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}

.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}

.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}

@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.ant-mention-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5715;
}

.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  opacity: 1;
}

.ant-mention-wrapper .ant-mention-editor::placeholder {
  color: #bfbfbf;
}

.ant-mention-wrapper .ant-mention-editor:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.ant-mention-wrapper .ant-mention-editor:focus,
.ant-mention-wrapper .ant-mention-editor-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-mention-wrapper .ant-mention-editor-borderless,
.ant-mention-wrapper .ant-mention-editor-borderless:hover,
.ant-mention-wrapper .ant-mention-editor-borderless:focus,
.ant-mention-wrapper .ant-mention-editor-borderless-focused,
.ant-mention-wrapper .ant-mention-editor-borderless-disabled,
.ant-mention-wrapper .ant-mention-editor-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-mention-wrapper .ant-mention-editor-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-mention-wrapper .ant-mention-editor-sm {
  padding: 0px 7px;
}

.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto;
}

.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}

.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}

.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}

.ant-mention-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}

.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color: #1890ff;
  text-align: center;
}

.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}

.ant-mention-dropdown-item:hover {
  background-color: #f5f5f5;
}

.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}

.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  background-color: #f5f5f5;
}

.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.input-field-modal-wrapper .ant-modal-body {
  padding: 0px;
}

.input-field-modal-wrapper .input-field-popup .ant-legacy-form-item {
  margin-bottom: 16px;
}

.input-field-modal-wrapper .input-field-popup .ant-drawer-footer {
  display: flex;
  justify-content: flex-end;
}

.text-block {
  height: 100%;
  transition: all 0.3s ease-in-out;
  word-break: break-word;
}

.text-block .mce-content-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #d8d8d8;
}

.text-block .mce-content-body h1,
.text-block .mce-content-body h2,
.text-block .mce-content-body h3,
.text-block .mce-content-body h4,
.text-block .mce-content-body h5,
.text-block .mce-content-body h6 {
  page-break-inside: avoid;
}

.text-block .mce-content-body table ol, .text-block .mce-content-body table ul {
  padding-left: 20px;
}

.text-block .mce-content-body p:last-child, .text-block .mce-content-body h1:last-child, .text-block .mce-content-body h2:last-child, .text-block .mce-content-body h3:last-child, .text-block .mce-content-body h4:last-child, .text-block .mce-content-body h5:last-child, .text-block .mce-content-body h6:last-child, .text-block .mce-content-body span:last-child, .text-block .mce-content-body div:last-child, .text-block .mce-content-body table:last-child, .text-block .mce-content-body thead:last-child, .text-block .mce-content-body tbody:last-child, .text-block .mce-content-body tfoot:last-child, .text-block .mce-content-body tr:last-child, .text-block .mce-content-body th:last-child, .text-block .mce-content-body td:last-child, .text-block .mce-content-body a:last-child, .text-block .mce-content-body hr:last-child, .text-block .mce-content-body ul:last-child, .text-block .mce-content-body ol:last-child, .text-block .mce-content-body dl:last-child {
  margin-bottom: 0px !important;
}

.text-block .mce-content-body .mce-resizehandle {
  background-color: #2f70ff;
  border-color: #2f70ff;
  display: none;
}

.text-block .tox.tox-tinymce {
  border-bottom: none;
  min-height: 100%;
}

.text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border: 1px solid #d8d8d8;
}

.text-block.read-only .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border: none;
}

.text-block .merge_tag-dropdown-container {
  position: absolute;
  z-index: 10;
  margin-top: 4px;
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.text-block .merge_tag-dropdown-container .search-input {
  width: 202px;
  height: 32px;
}

.text-block .merge_tag-dropdown-container.no-input-field .cascader {
  height: 3rem !important;
}

.document-customer-view .text-block .mce-content-body {
  border-color: transparent;
}

.ant-cascader-menus {
  border-radius: 4px 4px 0px 0px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item {
  margin: 0px 4px;
  border-radius: 4px 4px 0px 0px;
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
}

.ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  font-weight: unset;
  color: #2f70ff;
}

.ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item:hover {
  background: #f7f7f7;
  color: #2f70ff;
}

.ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item .text-selection {
  color: #2f70ff;
}

.ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item .bottom_action {
  margin: 0px -16px;
  position: absolute;
  width: 168px;
  padding: 12px 16px;
  bottom: 0px;
  background: #ffffff;
  color: #2f70ff;
  border-radius: 0px 0px 4px 4px;
  border-top: 1px solid #d8d8d8;
}

.add-variable-form .modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.ant-dropdown.merge_tag-edit-dropdown .edit-variable-form {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  padding: 20px;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  min-width: 280px;
}

.ant-dropdown.merge_tag-edit-dropdown .edit-variable-form .popup-footer {
  display: flex;
  flex-direction: row-reverse;
}

.input-field-popup .ant-slider-track {
  background-color: #2f70ff;
}

.input-field-popup .ant-slider-handle {
  border: 2px solid #2f70ff;
}

.input-field-popup .ant-cascader-menu {
  padding-bottom: 0px;
}

.input-field-popup .ant-cascader-menus .ant-cascader-menu:first-child {
  height: auto;
}

.input-field-popup .ant-cascader-menus .ant-cascader-menu:first-child .ant-cascader-menu-item {
  width: 19rem;
}

.mce-content-body table {
  float: none;
}

.input-field[data-field-externalid] .input-field-print,
.input-field[data-field-externalid] span:not(:first-child) {
  letter-spacing: -3px;
}

.input-field-placeholder {
  position: relative;
}

.tox-silver-sink .tox-pop {
  max-width: none !important;
}

@media print {
  .input-field-print {
    color: rgba(0, 0, 0, 0);
  }
}

@media print and print and (-webkit-min-device-pixel-ratio: 0) {
  .input-field-print {
    color: #ccc;
    -webkit-print-color-adjust: exact;
  }
}

.tox-notification--error {
  visibility: hidden;
}

.cascader {
  height: 6rem !important;
}

@import "~video.js/dist/video-js.min.css";

.video-block {
  width: auto;
  background-color: #f7f7f7;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.video-block.is-focused {
  -webkit-transform: scale(1.05) translateY(-5px);
          transform: scale(1.05) translateY(-5px);
}

.video-block .video-js {
  background-color: transparent;
  color: #ffffff;
  position: inherit;
  height: 100%;
  width: 100%;
}

.video-block .video-js video.vjs-tech {
  height: 100%;
}

.video-block .non-centered .video-js video.vjs-tech {
  width: auto;
  max-width: 100%;
}

.video-block.readonly {
  background-color: transparent;
}

.video-block .empty-video {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}

.video-block .video-block-child {
  position: relative;
  will-change: transform;
  z-index: 1;
}

.video-block .video-block-child:hover {
  z-index: 2;
}

.video-block .video-block-child .text-block {
  height: 100%;
  width: 100%;
}

.video-block .video-block-child .text-block .tox.tox-tinymce {
  min-height: 100%;
}

.video-block .video-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border: none;
}

.video-block .video-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area .tox-edit-area__iframe {
  background-color: transparent;
}

.video-block .video-block-child .delete-icon,
.video-block .video-block-child .reorder-icon {
  position: absolute;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: grid;
  place-items: center;
  right: -24px;
  -webkit-user-select: none;
          user-select: none;
  transition: all 0.3s ease-in-out;
  will-change: transform, opacity;
  -webkit-transform: translateX(-26px) scale(0);
          transform: translateX(-26px) scale(0);
  opacity: 0;
}

.video-block .video-block-child .delete-icon img,
.video-block .video-block-child .reorder-icon img,
.video-block .video-block-child .delete-icon svg,
.video-block .video-block-child .reorder-icon svg {
  vertical-align: middle;
}

.video-block .video-block-child .delete-icon.show,
.video-block .video-block-child .reorder-icon.show {
  opacity: 1;
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
}

.video-block .video-block-child .reorder-icon {
  top: 2px;
  cursor: grab;
}

.video-block .video-block-child .reorder-icon img,
.video-block .video-block-child .reorder-icon svg {
  height: 14px;
  width: 14px;
}

.video-block .video-block-child .delete-icon {
  top: 32px;
  cursor: pointer;
}

.video-block .add-cell-panel {
  font-size: 14px;
}

.video-block .add-cell-panel .cell-panel-btn-wrapper {
  position: absolute;
  height: 32px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.video-block .add-cell-panel .cell-panel-btn-wrapper .hover-visible {
  opacity: 0;
}

.video-block .add-cell-panel .cell-panel-btn-wrapper > .cell-button {
  height: 32px;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  margin: 0px 6px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  will-change: opacity;
  opacity: 0;
}

.video-block .add-cell-panel .cell-panel-btn-wrapper > .cell-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.video-block:hover .add-cell-panel .cell-panel-btn-wrapper > .cell-button {
  opacity: 1;
}

.video-block:hover .add-cell-panel .cell-panel-btn-wrapper .hover-visible {
  opacity: 1;
}

.video-block .youtube-block {
  height: 100%;
  width: 100%;
}

.video-block .video-block-youtube-iframe {
  padding: 5px;
  height: 100%;
  width: 100%;
}

.video-block .yt-thumbnail-box {
  display: none;
}

.video-block .yt-thumbnail-box img {
  height: 100%;
  width: 100%;
}

@media print {
  .video-block .yt-thumbnail-box {
    display: block !important;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-block .video-block-youtube-iframe {
    display: none !important;
  }
}

.video-uploader .zUploadModalWrapper {
  width: 100% !important;
  height: 20rem;
}

.video-uploader .zUploadModalWrapper .uppy-DragDrop-container[type="button"] {
  width: 100% !important;
  margin-top: 1rem;
}

.signature-block {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.signature-block.is-focused {
  -webkit-transform: scale(1.05) translateY(-5px);
          transform: scale(1.05) translateY(-5px);
  z-index: 1;
}

.signature-block.is-focused .signature-block-placeholder .signee-placeholder {
  -webkit-transform: scale(0.95) translateY(5px);
          transform: scale(0.95) translateY(5px);
}

.signature-block .signature-block-wrapper svg {
  height: 82px;
}

.signature-block .signature-signer-name {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-block .signature-block-placeholder {
  width: 230px;
  height: 100px;
  background: #f7f7f7;
  border: thin;
  border-color: #d8d8d8;
  display: flex;
  border-style: double;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.signature-block .signature-block-placeholder.for-pdf {
  color: #f7f7f7;
  height: 64px;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  margin: 0px;
  padding: 0px;
}

.signature-block .signature-block-placeholder.for-pdf > div.sign-tag {
  -webkit-transform: scaleY(2);
          transform: scaleY(2);
}

.signature-block .signature-block-placeholder .signature-label {
  color: #5670a1;
  font-size: 1rem;
}

.signature-block .signature-block-placeholder .signee-placeholder {
  max-width: 202px;
  width: 100%;
  height: 36px;
}

.signature-block .signature-block-placeholder .signer-dropdown {
  width: 100%;
}

.signature-block .signature-block-placeholder .signer-label-grid {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: center;
  margin-bottom: 0.2rem;
}

.signee-email-modal .email-save-button {
  width: 6.2rem;
  height: 1.7rem;
}

.signature-block.is-pdf-page-child .signer-dropdown {
  width: 85%;
}

.page-break-block {
  text-align: center;
  position: relative;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  page-break-before: always;
  font-size: 14px;
}

.page-break-block span {
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
  color: #ababab;
  padding: 0 10px;
  z-index: 0;
  -webkit-user-select: none;
          user-select: none;
}

.page-break-block:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  line-height: 0.1em;
}

.page-break-block.is-pdf {
  visibility: hidden;
}

.page-break-block.readonly {
  visibility: hidden;
}

.grid-block .grid-cell:first-child {
  margin-left: 0;
}

.grid-block .grid-cell {
  flex: 1 1 0%;
  margin-left: 1rem;
}

.grid-block + .grid-block {
  margin-top: 1rem;
}

.grid-block .cell {
  background: #f7f7f7;
  transition: all 0.3s ease-in-out;
  display: grid;
  height: 100%;
  position: relative;
}

.grid-block .cell.is-focused:not(.readonly) {
  cursor: auto;
  background: #f7f7f7;
  -webkit-transform: scale(1.05) translateY(-5px);
          transform: scale(1.05) translateY(-5px);
  box-shadow: 0 10px 30px -20px;
}

.grid-block .cell.readonly {
  background: transparent;
  cursor: auto;
}

.grid-block .cell.empty {
  min-height: 80px;
  display: grid;
  place-items: center;
  text-align: center;
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.grid-block .cell.empty:is(.readonly) {
  display: block !important;
}

.grid-block .cell.empty .add-cell-panel {
  font-size: 14px;
}

.grid-block .cell.empty .subtitle {
  opacity: 0;
  will-change: opacity;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  letter-spacing: -0.07px;
  text-align: center;
  color: #5670a1;
}

.grid-block .cell.empty .cell-panel-btn-wrapper {
  margin-top: 0.5rem;
  display: flex;
}

.grid-block .cell.empty .cell-panel-btn-wrapper svg:not(.disabled) rect {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.grid-block .cell.empty .cell-panel-btn-wrapper > div {
  margin-left: 8px;
  margin-right: 8px;
  width: -webkit-max-content;
  width: max-content;
}

.grid-block .cell.empty .cell-panel-btn-wrapper .disabled {
  pointer-events: none;
}

.grid-block .cell.empty .cell-panel-btn-wrapper .cell-button {
  will-change: opacity;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 32px;
  margin-left: 0px;
  opacity: 0;
  width: 32px;
}

.grid-block .cell.empty .cell-panel-btn-wrapper .cell-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.grid-block .cell.empty:hover {
  min-height: 80px;
}

.grid-block .cell.empty:hover .subtitle {
  opacity: 1;
}

.grid-block .cell.empty:hover .cell-panel-btn-wrapper .cell-button {
  opacity: 1;
}

.grid-block .cell .signature-block {
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  display: inline-block;
}

.grid-block .cell .signature-block .signature-block-placeholder {
  width: auto;
}

.signature-grid-blocks-3 .signer-dropdown .signee-placeholder {
  max-width: 180px;
}

.signature-grid-blocks-4 .signer-dropdown .signee-placeholder {
  max-width: 120px;
}

.risk-matrix-block-component {
  background: #ffffff;
}

.risk-matrix-block-component .risk-matrix-block-table {
  overflow: auto;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 6px;
  border: solid 1px #d8d8d8;
  border-radius: 6px;
  font-size: 1rem;
}

.risk-matrix-block-component .risk-matrix-block-table table {
  background: #f7f7f7;
  table-layout: fixed;
}

.risk-matrix-block-component .risk-matrix-block-table td {
  width: 25%;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell {
  min-width: 120px;
  min-height: 100px;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell .ant-popover-content {
  overflow: auto;
  max-height: 20rem;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell .edit-icon {
  float: right;
  visibility: hidden;
  position: absolute;
  left: 90%;
  bottom: 80%;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell .edit-icon-visible {
  float: right;
  position: absolute;
  left: 90%;
  bottom: 80%;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell:hover .edit-icon {
  visibility: visible;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container {
  position: relative;
  background: #f7f7f7;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container .matrix-cell-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container .matrix-cell-content {
  position: absolute;
  height: 100%;
  width: 100%;
  font-weight: 500;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container .matrix-cell-content .severity {
  float: right;
  padding: 10px;
  width: 8rem;
  overflow: hidden;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container .matrix-cell-content .severity:hover {
  display: block;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.parent-container .matrix-cell-content .likelihood {
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 10px;
  width: 9rem;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.issue-count {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 20px;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.label .caption {
  color: #7f7f7f;
}

.risk-matrix-block-component .risk-matrix-block-table .highlight-cell.label .title {
  width: 8rem;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.risk-matrix-block-component .legend-container {
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.risk-matrix-block-component .legend-container .legend-label {
  display: flex;
  width: 33.333333%;
}

.risk-matrix-block-component .legend-container .legend-label .legend {
  display: flex;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 12px;
  padding-left: 40px;
}

.risk-matrix-block-component .legend-container .legend-label .legend .sign {
  border-radius: 9999px;
  height: 8px;
  margin: 8px;
  margin-right: 12px;
  width: 8px;
}

.risk-matrix-block-component .legend-container .legend-label .edit-icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  cursor: pointer;
  display: grid;
  place-items: center;
  float: right;
  visibility: hidden;
}

.risk-matrix-block-component .legend-container .legend-label .edit-icon-visible {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  cursor: pointer;
  display: grid;
  place-items: center;
  float: right;
}

.risk-matrix-block-component .legend-container .legend-label:hover .edit-icon {
  visibility: visible;
}

.error-bg {
  background-color: #d9363e !important;
}

.warning-bg {
  background-color: #ffb200 !important;
}

.info-bg {
  background-color: #27ae60 !important;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container {
  max-height: 13rem;
  overflow: hidden;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-matrix-block-component {
  height: 10rem;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-matrix-block-component .risk-matrix-block-table {
  -webkit-transform: scale(0.3);
          transform: scale(0.3);
  height: 8rem;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  overflow: unset;
  width: 54rem;
  pointer-events: none;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-matrix-block-component .legend-container {
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
  width: 40rem;
  -webkit-transform-origin: left;
          transform-origin: left;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-listing-component-popover-container {
  height: 10rem;
  overflow: hidden;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-listing-component-popover-container .risk-listing-component {
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
  width: 41.4rem;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  border-style: none;
  overflow: hidden;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-score-component {
  background-color: transparent;
  border-radius: 0.375rem;
  padding: 8px;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-score-component .risk-bar {
  height: 8px;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-score-component .risk-bar-marker {
  height: 40px;
  margin-bottom: 4px;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-score-component .risk-bar-marker .pointer .score {
  font-size: 12px;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .risk-score-component .risk-bar-marker .pointer .label {
  font-size: 4px;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .legend-table {
  height: 10rem;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .legend-table .ant-table-wrapper {
  -webkit-transform: scale(0.275);
          transform: scale(0.275);
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .legend-table .ant-table-wrapper .ant-table {
  width: 60rem;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .legend-table .legend-text {
  font-size: 12px !important;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .health-status-card {
  height: 10rem;
  border-style: none;
  margin-bottom: 0px;
  overflow: hidden;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .health-status-card .health-status-title {
  display: none !important;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .health-status-card .ant-table-wrapper {
  -webkit-transform: scale(0.275);
          transform: scale(0.275);
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.mini-qbr-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .mini-qbr-popover-container .health-status-card .ant-table-wrapper .ant-table {
  width: 60rem;
}

.edit-risk-popover {
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.edit-risk-popover .ant-popover-content {
  width: 18rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.edit-risk-popover .ant-popover-content .ant-legacy-form {
  position: relative;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form .ant-input {
  border-radius: 0.4rem;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form .defaults-button {
  color: #2f70ff;
  position: absolute;
  top: -0.1rem;
  right: 0;
  z-index: 3;
  cursor: pointer;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form .button-container {
  display: flex;
  justify-content: flex-end;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form .button-container .save-button {
  margin-left: 0.5rem;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form-item {
  margin-bottom: 0px;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form-item:nth-last-child(2) {
  margin-bottom: 0.5rem;
}

.edit-risk-popover .ant-popover-content .ant-legacy-form-item .ant-legacy-form-item-label .ant-legacy-form-item-no-colon {
  color: #5670a1;
}

.risk-score-component {
  background: #ffffff;
  font-size: 1rem;
  page-break-inside: avoid;
  max-width: 680px;
}

.risk-score-component .risk-bar {
  height: 23px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  border-radius: 6px;
}

.risk-score-component .risk-bar-marker {
  position: relative;
  height: 74px;
  margin: 0px 5% 16px 0%;
}

.risk-score-component .risk-bar-marker .pointer {
  position: absolute;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.risk-score-component .risk-bar-marker .pointer .edit-icon {
  position: absolute;
  left: 80%;
  bottom: 65%;
  width: -webkit-max-content;
  width: max-content;
  height: 1.7rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  cursor: pointer;
}

.risk-score-component .risk-bar-marker .pointer .edit-icon-left {
  position: absolute;
  right: 80%;
  bottom: 65%;
  width: -webkit-max-content;
  width: max-content;
  height: 1.7rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  place-items: center;
  cursor: pointer;
}

.risk-score-component .risk-bar-marker .pointer .score {
  font-size: 24px;
  font-weight: 500;
}

.risk-score-component .risk-bar-marker .pointer .label {
  font-size: 12px;
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.risk-score-component .risk-bar-marker .pointer .triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid;
  margin: 4px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.risk-score-component .risk-bar-label-container {
  position: relative;
  height: 24px;
}

.risk-score-component .risk-bar-label-container .risk-bar-label {
  position: absolute;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.risk-score-component .risk-bar-label-container .risk-bar-label:nth-last-child(1) {
  right: 0px;
}

.risk-score-component .risk-bar-label-container .risk-bar-label:nth-last-child(2) {
  right: 50%;
}

.risk-listing-component {
  font-size: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
}

.risk-listing-component .risk-category-card {
  margin-bottom: 24px;
}

.risk-listing-component .risk-category-card:last-child {
  margin-bottom: 0px;
}

.risk-listing-component .risk-category-card .risk-section {
  border: solid 1px #d8d8d8;
  border-radius: 6px;
  overflow: hidden;
}

.risk-listing-component .risk-category-card .risk-section .heading {
  font-weight: 500;
  color: #ffffff;
  background-color: #5670a1;
  padding: 8px 26px;
  display: flex;
}

.risk-listing-component .risk-category-card .risk-section .heading .category-title {
  flex: 10;
}

.risk-listing-component .risk-category-card .risk-section .line-item {
  padding: 12px 15px;
  border-bottom: solid 1px #d8d8d8;
}

.risk-listing-component .risk-category-card .risk-section .line-item .sign.listing {
  background: #5670a1;
}

.risk-listing-component .risk-category-card .risk-section .line-item .issue_title {
  color: #5670a1;
  font-weight: 500;
}

.risk-listing-component .risk-category-card .risk-section .line-item:last-child {
  border-bottom: none;
}

.risk-listing-component .risk-category-card .risk-section .line-item .caption {
  color: #7f7f7f;
  white-space: pre-line;
}

.health-status-card {
  border: 1px solid #d9d9d9;
}

.health-status-card .health-status-title {
  background-color: #f5f5f5;
}

.health-status-card .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #d9d9d9;
  padding: 0.8rem;
  font-size: 12px;
}

.health-status-card .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 12px;
}

.payment-summary-column {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.image-block-child {
  will-change: transform;
  position: relative;
  z-index: 1;
}

.image-block-child .text-block {
  height: 100%;
  width: 100%;
}

.image-block-child .text-block .tox.tox-tinymce {
  min-height: 100%;
}

.image-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border-style: none;
}

.image-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area .tox-edit-area__iframe {
  background-color: transparent;
}

.image-block-child .delete-icon, .image-block-child .reorder-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: grid;
  place-items: center;
  height: 20px;
  opacity: 0;
  position: absolute;
  right: -24px;
  width: 20px;
}

.image-block-child .delete-icon,
.image-block-child .reorder-icon {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
          user-select: none;
  transition: all 0.3s ease-in-out;
  will-change: transform, opacity;
  -webkit-transform: translateX(-26px) scale(0);
          transform: translateX(-26px) scale(0);
}

.image-block-child .delete-icon img, .image-block-child .reorder-icon img, .image-block-child .delete-icon svg, .image-block-child .reorder-icon svg {
  vertical-align: middle;
}

.image-block-child .delete-icon.show, .image-block-child .reorder-icon.show {
  opacity: 1;
}

.image-block-child .delete-icon.show,
.image-block-child .reorder-icon.show {
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
}

.image-block-child .reorder-icon {
  top: 4px;
  cursor: grab;
}

.image-block-child .reorder-icon img, .image-block-child .reorder-icon svg {
  height: 16px;
  width: 16px;
}

.image-block-child .delete-icon {
  cursor: pointer;
  top: 32px;
}

.add-cell-panel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.add-cell-panel .cell-panel-btn-wrapper .hover-button {
  opacity: 0;
}

.add-cell-panel .cell-panel-btn-wrapper > .cell-button {
  cursor: pointer;
  display: inline-block;
  height: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 8px;
  opacity: 0;
  width: 32px;
  z-index: 10;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  will-change: opacity;
}

.add-cell-panel .cell-panel-btn-wrapper > .cell-button:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.paper-size-a4 .pdf-parent-document-container {
  --defaultPageWidth: 210mm;
}

.paper-size-letter .pdf-parent-document-container {
  --defaultPageWidth: 216mm;
}

.pdf-parent-document-container .disable-dragging-for-pdf {
  height: 100%;
  position: absolute;
  width: var(--defaultPageWidth);
  z-index: 1;
}

.pdf-parent-document-container .disable-dragging-for-pdf .disable-dragging-pattern-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 217, 217, var(--tw-bg-opacity));
  height: 100%;
  opacity: 0.8;
  position: relative;
  width: 100%;
}

.pdf-parent-document-container .disable-dragging-for-pdf .disable-dragging-pattern {
  height: 100%;
  position: absolute;
  width: 100%;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, #595959 35px, #595959 40px), repeating-linear-gradient(-45deg, transparent, transparent 35px, #595959 35px, #595959 40px);
  opacity: 0.05;
}

.pdf-parent-document-container .disable-dragging-for-pdf .disable-dragging-banner {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 89, 89, var(--tw-bg-opacity));
  height: 40px;
  position: absolute;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 100%;
  z-index: 1;
}

.legend-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead .ant-table-cell {
  padding: 0.8rem;
}

.legend-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 0.8rem;
}

.quote-block .quote-alerts {
  position: absolute;
  margin-top: 0.3rem;
  padding: 0.25rem 0.5em;
  border-radius: 6px;
}

.quote-block .quote-section .quote-section__header {
  background: #2f70ff;
  padding: 0.5rem 1rem;
  min-height: 4rem;
  font-family: inherit;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.quote-block .quote-section .quote-section__header .quote-section__options {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-gap: 1rem;
  margin-left: 16px;
}

.quote-block .quote-section .quote-section__header .quote-section__options .more-options-icon {
  height: 20px;
  width: 20px;
}

.quote-block .quote-section .quote-section__header .quote-section__options .ant-select-selection__rendered {
  width: 10rem;
}

.quote-block .quote-section .quote-section__header .ant-select {
  width: 100%;
}

.quote-block .quote-section .quote-section__header .quote-section__title {
  flex: 1;
  font-size: 14px;
}

.quote-block .quote-section .quote-section__header .quote-section__delete {
  cursor: pointer;
  width: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.quote-block .quote-section .quote-section__header .quote-section__delete.show {
  width: 14px;
}

.quote-block .quote-section .quote-section__header .quote-section__input {
  font-size: 14px;
  background: transparent;
  border: none;
  color: inherit !important;
}

.quote-block .quote-section .quote-section__header .quote-section__input:not(.subtitle) {
  font-weight: 500;
}

.quote-block .quote-section .quote-section__header .quote-section__input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 100;
}

.quote-block .quote-section .quote-section__header .quote-section__input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 100;
}

.quote-block .quote-section .quote-section__header .quote-section__input.subtitle {
  font-size: 12px;
}

.quote-block .quote-section .quote-section__product-wrapper .height-200px {
  height: 200px;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head {
  color: #333333;
  font-size: 13px;
  text-align: left;
  text-transform: capitalize;
  background: #ffffff;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 120px 54px 94px;
  grid-template-areas: "details price quantity final";
  padding-left: 28px;
  padding-right: 28px;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head .quantity-column {
  grid-area: quantity;
  text-align: right;
  align-self: flex-start;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head .price-column {
  grid-area: price;
  text-align: right;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head .price-column .inlineEditContainer {
  width: 16ch !important;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head .unit-of-measure-column {
  color: #8c8c8c;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__table-head .final-column {
  text-align: right;
  grid-area: final;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__empty-section {
  text-align: center;
  padding: 0.5rem 0;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__empty-section em {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}

.quote-block .quote-section .quote-section__product-wrapper .quote-section__button-wrapper {
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: 8rem 8rem;
  padding: 0.75rem 1rem;
  justify-content: center;
}

.quote-block .quote-section .quote-section__product-wrapper .single-button-wrapper {
  grid-template-columns: 8rem;
}

.quote-block .quote-product {
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 13px;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  page-break-inside: auto;
}

.quote-block .quote-product:last-child {
  border-bottom: none;
}

.quote-block .quote-product.bundle-products {
  page-break-inside: auto;
}

.quote-block .quote-product.is-draggable {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  border: none;
  box-shadow: 0 10px 10px -5px #cecece;
}

.quote-block .quote-product .quote-product__details {
  padding-left: 0px;
  transition: all 0.3s ease-in-out;
  position: relative;
  grid-area: details;
}

.quote-block .quote-product .quote-product__details .ant-tag-blue {
  background-color: #e7efff;
  color: #2f70ff;
  border-radius: 4px;
}

.quote-block .quote-product .quote-product__details .quote-product__name {
  word-break: break-word;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.quote-block .quote-product .quote-product__details .quote-product__name span {
  margin-right: 5px;
}

.quote-block .quote-product .quote-product__details .quote-product__desc {
  word-break: break-word;
  white-space: pre-line;
  font-family: inherit;
}

.quote-block .quote-product .quote-product__details .quote-product__images {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  page-break-inside: avoid;
  display: flex;
  flex-wrap: wrap;
}

.quote-block .quote-product .quote-product__details .quote-product__images .ant-image:hover {
  z-index: 1;
}

.quote-block .quote-product .quote-product__details .quote-product__images img {
  cursor: zoom-in;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  margin: 4px;
  object-fit: contain;
}

.quote-block .quote-product .quote-product__details .quote-product__images img:hover {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  will-change: transform;
}

.quote-block .quote-product .quote-product__details .quote-product__images .thumbnail_default {
  height: 2rem;
  width: 2rem;
}

.quote-block .quote-product .quote-product__details .quote-product__images .thumbnail_small {
  height: 2.57143rem;
  width: 2.57143rem;
}

.quote-block .quote-product .quote-product__details .quote-product__images .thumbnail_medium {
  height: 4rem;
  width: 4rem;
}

.quote-block .quote-product .quote-product__details .quote-product__images .thumbnail_large {
  height: 5.14286rem;
  width: 5.14286rem;
}

.quote-block .quote-product .quote-product__details .quote-product__select-wrapper {
  position: absolute;
  top: 0rem;
  left: 0rem;
}

.quote-block .quote-product .quote-product__details.has-options {
  padding-left: 2rem;
}

.quote-block .quote-product .quote-product__details.recurring-tag-wrap {
  margin-bottom: 0.25rem;
}

.quote-block .quote-product .quote-product__details .quote-product__discount-wrap {
  display: grid;
  grid-gap: 0.3rem;
  align-items: center;
  grid-template-columns: -webkit-max-content minmax(60px, -webkit-min-content) -webkit-min-content 60px;
  grid-template-columns: max-content minmax(60px, min-content) min-content 60px;
  margin-top: 0.5rem;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.quote-block .quote-product .quote-product__details .quote-product__discount-wrap.hidden,
.quote-block .quote-product .quote-product__details .quote-product__discount-wrap.secondary {
  opacity: 0.5;
}

.quote-block .quote-product .quote-product__details .quote-product__margin-wrap > span {
  margin-left: 0.5rem;
}

.quote-block .quote-product .preview-span {
  padding: 0.25rem 0.5rem;
}

.quote-block .quote-product .sub-total-wrap {
  padding: 0.38rem 0.5rem;
  padding-left: 0;
  position: relative;
}

.quote-block .quote-product .sub-total-wrap .sell-price-struck {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  will-change: opacity;
  position: relative;
  text-decoration: line-through;
}

.quote-block .quote-product .sub-total-wrap .final-price {
  position: relative;
  will-change: transform;
  width: 100%;
}

.quote-block .quote-product .sub-total-wrap .unit-of-measure-column {
  color: #8c8c8c;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  position: absolute;
  right: 0.5rem;
  will-change: transform;
  top: 1.8rem;
}

.quote-block .quote-product .sub-total-wrap.discounted .sell-price-struck {
  opacity: 1;
  color: #c3c3c3;
  text-decoration: line-through;
}

.quote-block .quote-product .sub-total-wrap.discounted .unit-of-measure-column {
  -webkit-transform: translateY(1.5rem);
          transform: translateY(1.5rem);
}

.quote-block .quote-product .quote-product__actions {
  position: absolute;
  top: 0.5rem;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: grid;
  place-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.quote-block .quote-product .quote-product__actions img,
.quote-block .quote-product .quote-product__actions svg {
  height: 14px;
  width: 14px;
}

.quote-block .quote-product .quote-product__actions.reorder-handle {
  left: -0.65rem;
}

.quote-block .quote-product .quote-product__actions.more-option {
  right: -0.65rem;
}

.quote-block .quote-product .quote-product__actions.more-option svg {
  color: #5670a1;
}

.quote-block .quote-product .product-hover-active {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  transition: all 150ms ease-out 500ms;
  opacity: 0;
}

.quote-block .quote-product .product-hover-active:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.quote-block .quote-product .product-hover-active {
  border-radius: 9999px;
}

.quote-block .quote-product .product-hover-active:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.quote-block .quote-product:hover .product-hover-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  transition-delay: 100ms;
}

.quote-block .bundle-products-wrap {
  page-break-inside: auto;
  display: grid;
  grid-gap: 1rem;
}

.quote-block .bundle-products-wrap .optional-products-box {
  padding: 1rem 0.8rem;
  border-radius: 6px;
  display: grid;
  grid-gap: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  margin-top: 8px;
  padding-right: 8px;
}

.quote-block .bundle-products-wrap .optional-products-box .child-quote-product__row.is-optional {
  grid-template-columns: 1rem 1fr 120px 60px 110px;
  grid-template-areas: "checkbox details price quantity final";
}

.quote-block .bundle-products-wrap .optional-products-box.has-options {
  margin-left: 2rem;
}

.quote-block .bundle-products-wrap .optional-products-box .quote-product__details .checkbox-label {
  cursor: pointer;
}

.quote-block .bundle-products-wrap .optional-products-box .quote-product__details .ant-tag-blue {
  background: white;
}

.quote-block .bundle-products-wrap .optional-products-box .quote-product__details.has-options {
  padding-left: 0;
}

.quote-block .bundle-products-wrap .optional-products-box .quote-product_row_additional_price, .quote-block .bundle-products-wrap .optional-products-box .quote-product_row_discount {
  padding-left: 16px;
}

.quote-block .quote-product__row {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 120px 60px 110px;
  grid-template-areas: "details price quantity final";
}

.quote-block .quote-product__row.additional .quote-product__discount-wrap {
  margin-top: 16px;
}

.quote-block .quote-product__row.discount {
  display: block;
}

.quote-block .quote-product__row.margin {
  margin-top: 1rem;
  color: #7f7f7f;
}

.quote-block .quote-product__row.commission {
  margin-top: 0.5rem;
  color: #7f7f7f;
}

.quote-block .quote-product__row .currency-span {
  overflow-wrap: break-word;
  text-align: right;
}

.quote-block .quote-product__row.hidden,
.quote-block .quote-product__row.secondary {
  opacity: 0.5;
}

.quote-block .quote-product__row .input-read-only {
  line-height: 1rem;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  width: 4rem;
}

.quote-block .quote-product__row.child-quote-product__row {
  margin: 0;
}

.quote-block .quote-product__row.child-quote-product__row .quote-product__details .quote-product__name {
  font-weight: 400;
}

.quote-block .quote-product__row.child-quote-product__row .quote-product__details .quote-product__setup-price-preview {
  margin-top: 4px;
}

.quote-block .quote-product__row .quantity-column {
  grid-area: quantity;
  text-align: right;
  align-self: flex-start;
}

.quote-block .quote-product__row .price-column {
  grid-area: price;
  text-align: right;
}

.quote-block .quote-product__row .price-column .inlineEditContainer {
  width: 16ch !important;
}

.quote-block .quote-product__row .unit-of-measure-column {
  color: #8c8c8c;
}

.quote-block .quote-product__row .final-column {
  text-align: right;
  grid-area: final;
}

.quote-block .sales-tax-divider {
  margin-top: 8px;
  margin-bottom: 8px;
}

.quote-block .sales-tax-divider.ant-divider {
  background: #e8e8e8;
}

.quote-block .quote-block__total {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.quote-block .quote-block__total .quote-block__total-card {
  padding-top: 1rem;
  border: 1px solid #e8e8e8;
  min-width: 300px;
  max-width: 420px;
  margin-top: 1rem;
  position: relative;
}

.quote-block .quote-block__total .quote-block__total-card .ant-input-number {
  width: 3.5rem;
}

.quote-block .quote-block__total .quote-block__total-card input {
  text-align: left;
}

.quote-block .quote-block__total .subtitle {
  color: #7f7f7f;
  font-size: 0.7rem;
}

.quote-block .quote-block__total .quote-block__total-key {
  page-break-inside: avoid;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  transition: opacity 0.3s ease-in-out;
  align-items: center;
}

.quote-block .quote-block__total .quote-block__total-key.hidden,
.quote-block .quote-block__total .quote-block__total-key.secondary {
  opacity: 0.4;
}

.quote-block .quote-block__total .quote-block__total-key.quote-block__total-grand {
  background-color: #eaf1ff;
  border-top: 1px solid #e8e8e8;
  padding: 1rem;
  transition: all 300ms ease-in-out;
}

.quote-block .quote-block__total .quote-block__total-key span {
  overflow-wrap: break-word;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .overflow {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key {
  font-size: 12px;
  padding: 0px;
  margin-left: 16px;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key:first-child {
  margin-top: 8px;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key:not(:last-child) {
  margin-bottom: 8px;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse {
  width: 100%;
  border: none;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item {
  border: none;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0;
  background-color: #ffffff;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header .anticon.anticon-caret-down {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg);
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header .anticon.anticon-caret-down svg {
  opacity: 0.4;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-content {
  border: none;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}

.quote-block .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header .anticon.anticon-caret-down {
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}

.quote-block .quote-block__total .quote-block__sales-tax {
  display: flex;
  min-width: 8.6rem;
  justify-content: space-between;
  margin-top: 0.5rem;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.quote-block .quote-block__total .quote-block__sales-tax.hidden,
.quote-block .quote-block__total .quote-block__sales-tax.secondary {
  opacity: 0.5;
}

.quote-block .quote-block__total .quote-block__sales-tax span.divider-sales-tax {
  margin: 0 0.3rem;
}

.quote-block .quote-block__total .quote-block__total-margin {
  background-color: #f7f7f7;
  color: #7f7f7f;
  padding: 0.5rem 1rem;
  border: 1px solid #e8e8e8;
  border-top: 0;
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.quote-block .quote-block__total .quote-block__total-margin svg {
  margin-right: 0.5rem;
}

.quote-block .quote-block__total .label-wrapper {
  display: flex;
  align-items: center;
}

.quote-block .quote-block__total .label-wrapper span {
  margin-right: 5px;
}

.quote-block .quote-block__total .tax-wrapper {
  width: 100%;
}

.quote-block .quote-block__total .tax-wrapper .quote-block__total-key {
  page-break-inside: avoid;
  padding: 0px;
  margin-top: 6px;
  margin-left: 16px;
  font-size: 13px;
}

.quote-block .quote-block__total .tax-wrapper .quote-block__total-key:first-child {
  margin-top: 8px;
}

.quote-block .quote-block-footer {
  position: relative;
}

.quote-block .quote-block-footer .new-section-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.quote-block .ant-btn,
.quote-block .ant-dropdown,
.quote-block .ant-select {
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.quote-block .ant-btn-default,
.quote-block .ant-btn-default:hover,
.quote-block .ant-btn-default:focus,
.quote-block .ant-btn-default:active {
  color: #2f70ff;
  background: #ffffff;
  border: 1px solid #2f70ff;
}

.quote-block .ant-btn-default:not(:disabled).ant-btn-loading .anticon,
.quote-block .ant-btn-default:hover:not(:disabled).ant-btn-loading .anticon,
.quote-block .ant-btn-default:focus:not(:disabled).ant-btn-loading .anticon,
.quote-block .ant-btn-default:active:not(:disabled).ant-btn-loading .anticon {
  color: #2f70ff;
}

.compact-view .quote-block .quote-block__total-card {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.compact-view .quote-block .optional-products-box {
  grid-gap: 0.5rem !important;
}

.compact-view .quote-block .sales-tax-divider {
  margin-top: 4px;
  margin-bottom: 4px;
}

.compact-view .quote-block .quote-block__total .quote-block__total-key.quote-block__total-grand {
  padding: 0.5rem 1rem;
}

.compact-view .quote-block .quote-block__sub-total-key:first-child {
  margin-top: 4px;
}

.compact-view .quote-block .quote-block__sub-total-key:not(:last-child) {
  margin-bottom: 4px;
}

.quote-product__image-modal {
  text-align: center;
}

.quote-product__image-modal .ant-modal-body {
  padding: 0px;
  height: 0px;
}

.quote-product__image-modal .quote-block-image-preview {
  max-height: 480px;
}

.quote-product__image-modal .ant-carousel .slick-slide {
  width: 480px;
  height: 480px;
}

.quote-product__image-modal .slick-dots {
  position: absolute;
  bottom: -20px;
}

.quote-product__image-modal .ant-modal-close-icon {
  position: absolute;
  top: -40px;
  right: -20px;
  color: #ffffff;
}

.quote-product__image-modal .image-modal__close {
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  right: 0px;
  top: -24px;
}

.document-customer-view .quote-block .quote-block__total-margin,
.document-customer-view .quote-block .hidden {
  display: none;
}

.ant-radio-group .ant-row .ant-col {
  font-size: 14px;
}

.width-sm {
  width: 12ch;
}

.product-thumbnails .ant-image {
  margin-right: 8px;
}

.product-thumbnails .ant-image .ant-image-img {
  object-fit: contain;
}

.menu-item {
  padding: 0.8rem 1.4rem;
}

.product-discount-container {
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.product-discount-container .product-discount-select {
  width: 6rem;
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.product-discount-container .product-discount-select:hover {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.product-discount-container .product-discount-select .ant-select-selector {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 0px;
}

.product-discount-container .product-discount-select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.product-discount-container .product-discount-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(
				.ant-select-customize-input
			) .ant-select-selector {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.product-discount-container .inlineEditContainer {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.product-discount-container .inlineInput {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.product-discount-container .inlineInput:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.product-discount-container .inlineInput {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.quote-block-v2 .quote-section .product-selection-round-checkboxes .ant-checkbox-inner {
  border-radius: 50%;
}

.quote-block-v2 .quote-section .quote-section__header {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
}

.quote-block-v2 .quote-section .quote-section__header .quote-section__title .hover-section-header .section-hover-active {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  transition: all 150ms ease-out 500ms;
}

.quote-block-v2 .quote-section .quote-section__header .quote-section__title .hover-section-header .section-hover-active:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.quote-block-v2 .quote-section .quote-section__header .quote-section__title .hover-section-header .section-hover-active {
  opacity: 0;
}

.quote-block-v2 .quote-section .quote-section__header .quote-section__title .hover-section-header:hover .section-hover-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition-delay: 100ms;
  opacity: 1;
}

.quote-block-v2 .quote-section .quote-section__header .quote-section__title .hover-section-header .section-drag {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .add-product-between-row .add-product-between-hover {
  opacity: 0;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .add-product-between-row:hover .add-product-between-hover {
  opacity: 1;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head {
  display: grid;
  opacity: 1;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .show-icon-hover {
  visibility: hidden;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .quantity-column:hover .show-icon-hover, .quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .discount-column:hover .show-icon-hover, .quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .price-column:hover .show-icon-hover, .quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .final-column:hover .show-icon-hover {
  visibility: visible;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .quantity-column {
  grid-area: quantity;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .discount-column {
  grid-area: discount;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .price-column {
  grid-area: price;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .final-column {
  padding-left: 4px;
  padding-right: 8px;
  text-align: right;
  grid-area: final;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__table-head .unit-of-measure-column {
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .quote-section__button-wrapper {
  grid-gap: 1rem;
  grid-template-columns: 8rem 8rem;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.quote-block-v2 .quote-section .quote-section__product-wrapper .single-button-wrapper {
  grid-template-columns: 8rem;
}

.quote-block-v2 .quote-product {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  position: relative;
  text-align: left;
  transition: all 0.3s ease-in-out;
  page-break-inside: auto;
}

.quote-block-v2 .quote-product.bundle-products {
  page-break-inside: auto;
}

.quote-block-v2 .quote-product.is-draggable {
  --tw-border-opacity: 1;
  border-color: rgba(89, 126, 247, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 10px 10px -5px #cecece;
}

.quote-block-v2 .quote-product .quote-product__details {
  padding-left: 0px;
  position: relative;
  transition: all 0.3s ease-in-out;
  grid-area: details;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__name {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__desc {
  word-break: break-word;
  white-space: pre-line;
  font-family: inherit;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images {
  page-break-inside: avoid;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 8px;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images .ant-image:hover {
  z-index: 10;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images img {
  cursor: zoom-in;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  margin: 4px;
  object-fit: contain;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images img:hover {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  will-change: transform;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images .thumbnail_default {
  height: 2rem;
  width: 2rem;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images .thumbnail_small {
  height: 2.57143rem;
  width: 2.57143rem;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images .thumbnail_medium {
  height: 4rem;
  width: 4rem;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__images .thumbnail_large {
  height: 5.14286rem;
  width: 5.14286rem;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__select-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
}

.quote-block-v2 .quote-product .quote-product__details.has-options {
  padding-left: 28px;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__discount-wrap {
  grid-gap: 0.3rem;
  grid-template-columns: -webkit-max-content minmax(60px, -webkit-min-content) -webkit-min-content 60px;
  grid-template-columns: max-content minmax(60px, min-content) min-content 60px;
  transition: all 0.3s ease-in-out;
  display: grid;
  align-items: center;
  margin-top: 4px;
  opacity: 1;
}

.quote-block-v2 .quote-product .quote-product__details .quote-product__discount-wrap.hidden, .quote-block-v2 .quote-product .quote-product__details .quote-product__discount-wrap.secondary {
  opacity: 0.5;
}

.quote-block-v2 .quote-product .sub-total-wrap .sell-price-struck {
  transition: all 0.3s ease-in-out;
  will-change: opacity;
  opacity: 0;
  position: relative;
  text-decoration: line-through;
}

.quote-block-v2 .quote-product .sub-total-wrap .final-price {
  will-change: transform;
  position: relative;
  width: 100%;
}

.quote-block-v2 .quote-product .sub-total-wrap .unit-of-measure-column {
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  will-change: transform;
  position: absolute;
  right: 8px;
  top: 24px;
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.quote-block-v2 .quote-product .sub-total-wrap.discounted .sell-price-struck {
  opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
  text-decoration: line-through;
}

.quote-block-v2 .quote-product .sub-total-wrap.discounted .unit-of-measure-column {
  -webkit-transform: translateY(1.5rem);
          transform: translateY(1.5rem);
}

.quote-block-v2 .quote-product .quote-product__actions {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.375rem;
  display: grid;
  place-items: center;
  height: 20px;
  padding: 4px;
  position: absolute;
  top: 8px;
  -webkit-user-select: none;
          user-select: none;
  width: 20px;
}

.quote-block-v2 .quote-product .quote-product__actions.reorder-handle {
  left: -0.65rem;
}

.quote-block-v2 .quote-product .quote-product__actions.more-option {
  right: -0.65rem;
}

.quote-block-v2 .quote-product .quote-product__actions.more-option svg {
  color: #5670a1;
}

.quote-block-v2 .quote-product .product-hover-active {
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  transition: all 150ms ease-out 500ms;
}

.quote-block-v2 .quote-product .product-hover-active:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.quote-block-v2 .quote-product .product-hover-active {
  opacity: 0;
}

.quote-block-v2 .quote-product .product-hover-active:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.quote-block-v2 .quote-product:hover .product-hover-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition-delay: 100ms;
  opacity: 1;
}

.quote-block-v2 .bundle-products-wrap {
  page-break-inside: auto;
  grid-gap: 16px;
  display: grid;
}

.quote-block-v2 .bundle-products-wrap .optional-products-box {
  grid-gap: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  display: grid;
  margin-top: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

.quote-block-v2 .bundle-products-wrap .optional-products-box.has-options {
  margin-left: 28px;
}

.quote-block-v2 .bundle-products-wrap .optional-products-box .quote-product__details.has-options {
  padding-left: 0px;
}

.quote-block-v2 .quote-product__row {
  transition: all 0.3s ease-in-out;
  display: grid;
  opacity: 1;
}

.quote-block-v2 .quote-product__row.additional .quote-product__discount-wrap {
  margin-top: 16px;
}

.quote-block-v2 .quote-product__row.discount {
  display: block;
}

.quote-block-v2 .quote-product__row.margin {
  margin-top: 12px;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.quote-block-v2 .quote-product__row.commission {
  margin-top: 8px;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.quote-block-v2 .quote-product__row .currency-span {
  overflow-wrap: break-word;
}

.quote-block-v2 .quote-product__row.hidden, .quote-block-v2 .quote-product__row.secondary {
  opacity: 0.5;
}

.quote-block-v2 .quote-product__row .quantity-column {
  grid-area: quantity;
  align-self: flex-start;
  padding-left: 4px;
  padding-right: 8px;
  text-align: right;
}

.quote-block-v2 .quote-product__row .discount-column {
  grid-area: discount;
  align-self: flex-start;
  padding-left: 4px;
  padding-right: 8px;
  text-align: right;
}

.quote-block-v2 .quote-product__row .price-column {
  grid-area: price;
  padding-left: 4px;
  padding-right: 8px;
  text-align: right;
}

.quote-block-v2 .quote-product__row .final-column {
  grid-area: final;
  padding-left: 4px;
  padding-right: 8px;
  text-align: right;
}

.quote-block-v2 .quote-product__row .unit-of-measure-column {
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.quote-block-v2 .quote-block__total {
  display: flex;
  justify-content: flex-end;
}

.quote-block-v2 .quote-block__total .quote-block__total-card .ant-input-number {
  width: 48px;
}

.quote-block-v2 .quote-block__total .subtitle {
  color: #7f7f7f;
  font-size: 0.7rem;
}

.quote-block-v2 .quote-block__total .quote-block__total-key {
  page-break-inside: avoid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.hidden, .quote-block-v2 .quote-block__total .quote-block__total-key.secondary {
  opacity: 0.4;
}

.quote-block-v2 .quote-block__total .quote-block__total-key span {
  overflow-wrap: break-word;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .overflow {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key {
  font-size: .75rem;
  margin-left: 16px;
  padding: 0px;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key:first-child {
  margin-top: 8px;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .quote-block__sub-total-key:not(:last-child) {
  margin-bottom: 8px;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse {
  width: 100%;
  border: none;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item {
  border: none;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0px;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header .anticon.anticon-down {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg);
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-header .anticon.anticon-down svg {
  opacity: 0.4;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-content {
  border: none;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0px;
}

.quote-block-v2 .quote-block__total .quote-block__total-key.tax-key .tax-label-wrapper-collapse.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header .anticon.anticon-down {
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}

.quote-block-v2 .ant-btn,
.quote-block-v2 .ant-dropdown,
.quote-block-v2 .ant-select {
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.quote-block-v2 .z-select .ant-select-selector {
  background-color: transparent;
}

.quote-block-v2 .ant-btn-default,
.quote-block-v2 .ant-btn-default:hover,
.quote-block-v2 .ant-btn-default:focus,
.quote-block-v2 .ant-btn-default:active {
  color: #2f70ff;
  background: #ffffff;
  border: 1px solid #2f70ff;
}

.quote-block-v2 .ant-btn-default:not(:disabled).ant-btn-loading .anticon,
.quote-block-v2 .ant-btn-default:hover:not(:disabled).ant-btn-loading .anticon,
.quote-block-v2 .ant-btn-default:focus:not(:disabled).ant-btn-loading .anticon,
.quote-block-v2 .ant-btn-default:active:not(:disabled).ant-btn-loading .anticon {
  color: #2f70ff;
}

.compact-view .quote-block-v2 .quote-block__total-key {
  padding-top: 4px;
  padding-bottom: 4px;
}

.compact-view .quote-block-v2 .optional-products-box {
  grid-gap: 0.5rem !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.compact-view .quote-block-v2 .quote-block__sub-total-key:first-child {
  margin-top: 4px;
}

.compact-view .quote-block-v2 .quote-block__sub-total-key:not(:last-child) {
  margin-bottom: 4px;
}

.quote-product__image-modal {
  text-align: center;
}

.quote-product__image-modal .ant-modal-body {
  height: 0px;
  padding: 0px;
}

.quote-product__image-modal .quote-block-image-preview {
  max-height: 480px;
}

.quote-product__image-modal .ant-carousel .slick-slide {
  height: 480px;
  width: 480px;
}

.quote-product__image-modal .slick-dots {
  position: absolute;
  bottom: -20px;
}

.quote-product__image-modal .ant-modal-close-icon {
  position: absolute;
  right: -20px;
  top: -40px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.quote-product__image-modal .image-modal__close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: -24px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.width-sm {
  width: 12ch;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-title {
  padding: 12px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-list {
  padding: 0px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 12px;
}

.v2-inlineEditContainer {
  word-break: break-all;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}

.v2-inlineEditContainer:hover {
  --tw-border-opacity: 1;
  border-color: rgba(89, 89, 89, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
}

.v2-inlineEditContainer.disabled {
  cursor: not-allowed;
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.v2-inlineEditContainer.inline-edit-skeleton {
  border-style: none;
  padding: 0px;
}

.v2-inlineEditContainer .inlineEditWidth-xs {
  width: 6ch;
}

.v2-inlineEditContainer .inlineEditWidth-sm {
  width: 12ch;
}

.v2-inlineEditContainer .inlineEditWidth-md {
  width: 22ch;
}

.v2-inlineEditContainer .inlineEditWidth-lg {
  width: 30ch;
}

.v2-inlineEditContainer .inlineEditWidth-xl {
  width: 38ch;
}

.inputPlaceholder {
  border-radius: 0.25rem;
}

.v2-inlineInput {
  outline: none !important;
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.v2-inlineInput:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.v2-inlineInput {
  border-style: solid;
  border-width: 1px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.minWidth-xs {
  min-width: calc(6ch - 18px);
}

.minWidth-sm {
  min-width: calc(12ch - 18px);
}

.minWidth-md {
  min-width: calc(22ch - 18px);
}

.minWidth-lg {
  min-width: calc(30ch - 18px);
}

.minWidth-xl {
  min-width: calc(38ch - 18px);
}

.inline-edit-skeleton .ant-skeleton-title {
  height: 30px;
  margin: 0px;
}

.inline-editable-quote-block-label .z-input .ant-input {
  border-radius: 0px !important;
  border-right: none !important;
  --tw-border-opacity: 1;
  border-color: rgba(191, 191, 191, var(--tw-border-opacity));
  border-style: dashed;
  border-width: 0px;
  border-bottom-width: 1px;
}

.inline-editable-quote-block-label .z-input .ant-input:focus,
.inline-editable-quote-block-label .z-input .ant-input:active {
  border-bottom: 1px solid #4b85ff;
  border-radius: 0px !important;
}

.inline-editable-quote-block-label .z-input .ant-input:focus, .inline-editable-quote-block-label .z-input .ant-input:active {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.quote-product .discount-column .z-select .ant-select-selector {
  border-color: transparent;
}

.quote-product .discount-column .z-select .ant-select-selector:focus {
  --tw-border-opacity: 1;
  border-color: rgba(89, 126, 247, var(--tw-border-opacity));
}

.quote-product .discount-column .z-select .ant-select-selector {
  border-radius: 0px;
}

.image-block {
  width: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto auto;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 0;
}

.image-block.is-focused {
  -webkit-transform: scale(1.05) translateY(-5px);
          transform: scale(1.05) translateY(-5px);
}

.image-block.readonly {
  background-color: transparent;
}

.image-block .empty-image {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}

.image-block .image-block-child {
  position: relative;
  will-change: transform;
  z-index: 1;
}

.image-block .image-block-child .text-block {
  height: 100%;
  width: 100%;
}

.image-block .image-block-child .text-block .tox.tox-tinymce {
  min-height: 100%;
}

.image-block .image-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border: none;
}

.image-block .image-block-child .text-block .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area .tox-edit-area__iframe {
  background-color: transparent;
}

.image-block .image-block-child .delete-icon,
.image-block .image-block-child .reorder-icon {
  position: absolute;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: grid;
  place-items: center;
  right: -24px;
  -webkit-user-select: none;
          user-select: none;
  transition: all 0.3s ease-in-out;
  will-change: transform, opacity;
  -webkit-transform: translateX(-26px) scale(0);
          transform: translateX(-26px) scale(0);
  opacity: 0;
}

.image-block .image-block-child .delete-icon img,
.image-block .image-block-child .reorder-icon img,
.image-block .image-block-child .delete-icon svg,
.image-block .image-block-child .reorder-icon svg {
  vertical-align: middle;
}

.image-block .image-block-child .delete-icon.show,
.image-block .image-block-child .reorder-icon.show {
  opacity: 1;
  -webkit-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
}

.image-block .image-block-child .reorder-icon {
  top: 2px;
  cursor: grab;
}

.image-block .image-block-child .reorder-icon img,
.image-block .image-block-child .reorder-icon svg {
  height: 14px;
  width: 14px;
}

.image-block .image-block-child .delete-icon {
  top: 32px;
  cursor: pointer;
}

.image-block .add-cell-panel {
  font-size: 14px;
}

.image-block .add-cell-panel .cell-panel-btn-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.image-block .add-cell-panel .cell-panel-btn-wrapper .hover-button {
  opacity: 0;
}

.image-block .add-cell-panel .cell-panel-btn-wrapper > .cell-button {
  height: 32px;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  margin: 0px 6px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  will-change: opacity;
  opacity: 0;
}

.image-block .add-cell-panel .cell-panel-btn-wrapper > .cell-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.image-block:hover .add-cell-panel .cell-panel-btn-wrapper > .cell-button {
  opacity: 1;
}

.image-block:hover .add-cell-panel .cell-panel-btn-wrapper .hover-button {
  opacity: 1;
}

.image-uploader .zUploadModalWrapper {
  width: 100% !important;
  height: 20rem;
}

.image-uploader .zUploadModalWrapper .uppy-DragDrop-container[type="button"] {
  width: 100% !important;
  margin-top: 1rem;
}

.grid-block-control-panel {
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 12.143rem 0.143rem 12.143rem 3rem 3rem;
  width: 100%;
}

.grid-block-control-panel .ant-input-number {
  width: 12.143rem;
  background: #f3f3f3;
  border: none;
  position: relative;
}

.grid-block-control-panel .ant-input-number:hover:after {
  right: 2rem;
}

.grid-block-control-panel .ant-input-number:after {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #8798ad;
  transition: all 0.3s ease-in-out;
}

.grid-block-control-panel .ant-input-number.row-input:after {
  content: "Row";
}

.grid-block-control-panel .ant-input-number.column-input:after {
  content: "Column";
}

.grid-block-control-panel .ant-btn {
  border: none;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.grid-block-control-panel .ant-btn:disabled {
  opacity: 0;
}

.text-form-popup-form {
  min-width: 200px;
  margin-left: -22px;
}

.text-form-popup-form .text-form-title {
  font-style: normal;
  font-weight: bold;
  color: #0c1d33;
  margin-bottom: 0.5rem;
}

.text-form-popup-form .text-form-input {
  width: 100%;
}

.pdf-upload-modal .close-icon {
  -webkit-transform: translateX(-1.25rem);
          transform: translateX(-1.25rem);
}

.pdf-upload-modal .ant-modal {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.pdf-upload-modal .ant-modal .ant-modal-body {
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  max-height: 70vh;
}

.pdf-upload-modal .ant-upload-list .ant-upload-list-text-container {
  max-width: 420px;
  padding-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdf-upload-modal .ant-upload-list .ant-tooltip-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.paper-size-a4 .z-proposal-page {
  --defaultPDFPageWidth: 210mm !important;
  --defaultPDFPageHeight: 296mm !important;
}

.paper-size-letter .z-proposal-page {
  --defaultPDFPageWidth: 216mm !important;
  --defaultPDFPageHeight: 278mm !important;
}

.z-proposal-page {
  position: relative;
  --defaultPDFPageXPadding: 56px !important;
  --defaultPDFPageYPadding: 72px !important;
  --defaultPDFContentWidth: calc(var(--defaultPDFPageWidth) - calc(2 * var(--defaultPDFPageXPadding)));
  --defaultPDFContentHeight: calc(var(--defaultPDFPageHeight) - calc(2 * var(--defaultPDFPageYPadding)));
}

.z-proposal-page .add-page-option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.z-proposal-page .add-page-option-container .add-page-option {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 32px;
  margin-right: 32px;
  width: 160px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.z-proposal-page .add-page-option-container .add-page-option:hover:not(.disabled-page-option) {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(89, 126, 247, var(--tw-text-opacity));
}

.z-proposal-page .add-page-option-container .add-page-option.disabled-page-option {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  cursor: default;
}

.z-proposal-page .document-editor-page.pdf-document {
  background-color: transparent !important;
}

.z-proposal-page .document-editor-page.pdf-document .droppable-area {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.z-proposal-page .document-editor-page.pdf-document.cropped-pdf {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.z-proposal-page .document-editor-page.pdf-document .ant-card-body {
  padding: 0px !important;
}

.z-proposal-page .document-editor-page.pdf-document .ant-card-body .document-a4-size {
  width: var(--defaultPageWidth);
}

.z-proposal-page .document-editor-page.pdf-document .react-pdf__Page__canvas,
.z-proposal-page .document-editor-page.pdf-document .react-pdf__Page__textContent {
  width: var(--defaultPDFPageWidth) !important;
  height: var(--defaultPDFPageHeight) !important;
}

.z-proposal-page.headers-footers .document-editor-page .ant-card-body .droppable-area .blocks-wrapper {
  min-height: calc(var(--defaultPDFPageHeight) - var(--defaultPDFPageYPadding) * 2) !important;
}

.z-proposal-page.headers-footers .document-editor-page.pdf-document.cropped-pdf .document-a4-size,
.z-proposal-page.headers-footers .document-editor-page.pdf-document.cropped-pdf .disable-dragging-for-pdf {
  width: var(--defaultPDFContentWidth) !important;
}

.z-proposal-page.headers-footers .document-editor-page.pdf-document.cropped-pdf .react-pdf__Page {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  overflow: hidden;
  width: var(--defaultPDFContentWidth) !important;
  height: var(--defaultPDFContentHeight) !important;
}

.z-proposal-page.headers-footers .document-editor-page.pdf-document.cropped-pdf .react-pdf__Page__canvas {
  object-position: calc(-1 * var(--defaultPDFPageXPadding)) calc(-1 * var(--defaultPDFPageYPadding));
}

.z-proposal-page.headers-footers .document-editor-page.pdf-document.cropped-pdf .react-pdf__Page__textContent {
  left: calc(-1 * var(--defaultPDFPageXPadding)) !important;
  top: calc(-1 * var(--defaultPDFPageYPadding)) !important;
}

.headers-and-footers.header-footer-add {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
}

.headers-and-footers.header-footer-add .header-footer-add-btn {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  padding-left: 32px;
  padding-right: 32px;
}

.headers-and-footers.header-footer-edit, .headers-and-footers.header-footer-view {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-style: solid;
  border-width: 0px;
  height: 72px;
  padding: 0px;
  position: relative;
}

.headers-and-footers.header-footer-edit .header-footer-container, .headers-and-footers.header-footer-view .header-footer-container {
  display: grid;
  height: 72px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 56px;
  padding-right: 56px;
  width: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.headers-and-footers.header-footer-edit {
  border-width: 0px !important;
  z-index: 3 !important;
}

.headers-and-footers.header-footer-edit.footer {
  height: 84px;
}

.headers-and-footers.header {
  border-bottom-width: 1px;
}

.headers-and-footers.footer {
  border-top-width: 1px;
}

.headers-and-footers.header-footer-view {
  height: 72px;
  -webkit-user-select: none;
          user-select: none;
}

.headers-and-footers .image-column {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
}

.headers-and-footers .image-column .ant-image {
  height: 100%;
  width: 100%;
}

.headers-and-footers .text-column {
  cursor: pointer;
  height: 32px;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.headers-and-footers .text-column p {
  height: 100%;
  margin: 0px;
  width: 100%;
}

.headers-and-footers .text-column.read-only-column {
  display: flex;
  align-items: center;
  height: 100%;
}

.headers-and-footers .text-column.read-only-column .merge-tag {
  background-color: transparent;
  cursor: default;
}

.headers-and-footers .text-column.editable-column .merge-tag {
  background-color: transparent !important;
  cursor: pointer !important;
}

.headers-and-footers .read-only-column {
  cursor: default;
}

.headers-and-footers .editing-column {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
}

.headers-and-footers .editing-column.active {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.headers-and-footers .editable-column {
  -webkit-user-select: none;
          user-select: none;
}

.column-input {
  transition: all 0.3s ease-in-out;
  height: 100%;
  position: relative;
}

.column-input.active-input .mce-content-body {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  overflow-x: auto;
}

.column-input .mce-content-body {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.column-input .mce-content-body.mce-edit-focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.column-input .mce-content-body h1,
.column-input .mce-content-body h2,
.column-input .mce-content-body h3,
.column-input .mce-content-body h4,
.column-input .mce-content-body h5,
.column-input .mce-content-body h6 {
  page-break-inside: avoid;
}

.column-input .mce-content-body table ol, .column-input .mce-content-body table ul {
  padding-left: 20px;
}

.column-input .mce-content-body p:last-child, .column-input .mce-content-body h1:last-child, .column-input .mce-content-body h2:last-child, .column-input .mce-content-body h3:last-child, .column-input .mce-content-body h4:last-child, .column-input .mce-content-body h5:last-child, .column-input .mce-content-body h6:last-child, .column-input .mce-content-body span:last-child, .column-input .mce-content-body div:last-child, .column-input .mce-content-body table:last-child, .column-input .mce-content-body thead:last-child, .column-input .mce-content-body tbody:last-child, .column-input .mce-content-body tfoot:last-child, .column-input .mce-content-body tr:last-child, .column-input .mce-content-body th:last-child, .column-input .mce-content-body td:last-child, .column-input .mce-content-body a:last-child, .column-input .mce-content-body hr:last-child, .column-input .mce-content-body ul:last-child, .column-input .mce-content-body ol:last-child, .column-input .mce-content-body dl:last-child {
  margin-bottom: 0px !important;
}

.column-input .mce-content-body .mce-resizehandle {
  background-color: #2f70ff;
  border-color: #2f70ff;
  display: none;
}

.column-input .tox.tox-tinymce {
  border-bottom-width: 0px;
  min-height: 100%;
}

.column-input .tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border: 1px solid #d8d8d8;
}

.column-input .merge_tag-dropdown-container, .column-input .col-input-dropdown-container {
  margin-top: 8px;
  position: absolute;
  z-index: 10;
}

.column-input .merge_tag-dropdown-container,
.column-input .col-input-dropdown-container {
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
}

.column-input .merge_tag-dropdown-container .search-input, .column-input .col-input-dropdown-container .search-input {
  height: 32px;
  width: 204px;
}

.column-input .merge_tag-dropdown-container .ant-dropdown.z-dropdown-overlay, .column-input .col-input-dropdown-container .ant-dropdown.z-dropdown-overlay {
  top: 0px !important;
  left: 0px !important;
}

.column-input .merge_tag-dropdown-container .ant-dropdown-menu-item .dropdown-item-icon, .column-input .col-input-dropdown-container .ant-dropdown-menu-item .dropdown-item-icon {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-right: 8px;
  padding: 2px;
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
  width: 24px;
}

.column-input .merge_tag-dropdown-container .ant-dropdown-menu-item:hover .dropdown-item-icon, .column-input .col-input-dropdown-container .ant-dropdown-menu-item:hover .dropdown-item-icon {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(47, 112, 255, var(--tw-text-opacity));
}

.document-customer-view .text-block .mce-content-body {
  border-color: transparent;
}

.ant-cascader-menus.merge-tags-cascader {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu {
  margin-right: 0px;
  width: 200px;
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu .ant-cascader-menu-item {
  text-overflow: ellipsis;
  border-radius: 0.25rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 4px;
  margin-bottom: 4px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  width: 200px;
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  font-weight: unset;
  color: #2f70ff;
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu .ant-cascader-menu-item:hover {
  background: #f7f7f7;
  color: #2f70ff;
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu .ant-cascader-menu-item .text-selection {
  color: #2f70ff;
}

.ant-cascader-menus.merge-tags-cascader .ant-cascader-menu .ant-cascader-menu-item .bottom_action {
  background: #ffffff;
  color: #2f70ff;
  border-top: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  margin-right: -16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  bottom: 0px;
  width: 200px;
}

.ant-cascader-picker-with-value + div .ant-cascader-menu.merge-tags-cascader {
  padding-bottom: 8px;
}

.add-variable-form .modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.ant-dropdown.merge_tag-edit-dropdown .edit-variable-form {
  background: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  border-radius: 0.25rem;
  min-width: 28px;
  padding: 20px;
  position: absolute;
}

.ant-dropdown.merge_tag-edit-dropdown .edit-variable-form .popup-footer {
  display: flex;
  flex-direction: row-reverse;
}

.mce-content-body table {
  float: none;
}

.tox-silver-sink .tox-pop {
  max-width: none !important;
}

.tox-notification--error {
  visibility: hidden;
}

.cascader {
  height: 6rem !important;
}

.document-editor-page.disabled-page::before {
  content: "";
  z-index: 2;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.google-document-page-views {
  height: 100%;
  overflow-y: hidden;
}

.google-document-page-views > iframe {
  border-style: none;
  height: 100%;
  width: 100%;
}
